<template>
  <div class="nft-card-detail text-white">
    <section
      @click="() => ($router.push(`/Series/${data.id}`))"
      class="nft-card-detail__img-container relative cursor-pointer"
    >
      <div class="cursor-pointer w-full h-full">
        <img
          class="w-full h-full object-cover rounded-2xl"
          :src="data.thumbnail_url"
        />
        <!--
        <div class="w-full h-full object-scale-down flex items-center justify-center">
          <img
            class="rounded-3xl cursor-pointer"
            :src="data.thumbnail_url"
          />
        </div>
        -->
        <div class="flex justify-center text-black mt-3 custom-text"> {{ showName }} </div>
     </div>
    </section>
    <div class="grid-cols-1 px-6 py-4 rounded-b-xl"></div>
  </div>
</template>
<!-- ====+=========+=========+=========+=========+=========+=========+====== -->
<script>
// import { CHAIN_INFO, TOKEN_SYMBOLS } from '@/constants/chains.constant';
import { CHAIN_INFO } from '@/constants/chains.constant';
import { SITE_URL } from '@/env';
// import moment from 'moment';
// import { generateCalendarLink } from '@/utils/link.util';
import htmlToFormattedText from 'html-to-formatted-text';
import { mapState } from 'vuex';
export default {
  name: 'SeriesCardItem',
  //-----+---------+---------+---------+---------+---------+---------+---------E
  components: {
    // CardTag,
    // GlobalIcon,
    // CalendarIcon,
    // TwitterIcon,
    // CryptoIcon,
  },
  //-----+---------+---------+---------+---------+---------+---------+---------E
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  //-----+---------+---------+---------+---------+---------+---------+---------E
  data() {
    return {
      CHAIN_INFO,
      computationRefreshKey: false,
      endTimeMilli: 0,
      SITE_URL,
    }; // End of return {...}
  }, // End of data() {...}
  //-----+---------+---------+---------+---------+---------+---------+---------E
  //created() {
  //  this.getTime();
  //},
  // ----+---------+---------+---------+---------+---------+---------+---------E
  computed: {
    ...mapState({
      locale: (state) => state?.setting.locale,
    }),
    // --+---------+---------+---------+---------+---------+---------+---------E
    showName() {
      return this.locale === 'ja' && this.data.name_ja ? this.data.name_ja : this.data.name_en;
    },
    // --+---------+---------+---------+---------+---------+---------+---------E
    showDescription() {
      return this.locale === 'ja' && this.data.description_ja
        ? this.data.description_ja
        : this.data.description;
    },
  }, // End of computed: {...}
  //-----+---------+---------+---------+---------+---------+---------+---------E
  methods: {
    htmlToFormattedText,
  },
};
</script>

<style
  lang="scss"
  scoped
>
.tight-leading {
  line-height: 0.9rem;
}
.nft-card-detail {
  @screen md {
    width: 245px;
  }
  width: 95%;
  margin: auto;
  &__img-container {
    aspect-ratio: 1 / 1;
    .can-not-buy-btns {
      @apply absolute top-1/2 left-1/2;
      transform: translate(-50%, -50%);
    }
  }
  &__body {
    @apply bg-card-item-bg;
    .card-section {
      @apply py-2;
      .property {
        @apply text-card-item-label;
      }
    }
  }
  &__info {
    height: 84px;
    overflow: hidden;

    .description {
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
      line-height: 0.9rem;
    }
  }
}
.timer {
  font-family: 'Lato', 'Poppins', sans-serif;
  font-variant-numeric: tabular-nums;
  .time-unit-box {
    @apply w-1/5;
  }
  .colon-box {
    width: 7%;
  }
}

.custom-text {
  color: black;
  font-size: 18px;
  font-weight: bold;
  
  @media (max-width:768px) {
    font-size: 14px;
  }
}

.icon-scale {
  @apply w-7 h-7;
  @screen xxs {
    @apply w-10 h-10;
  }
  @screen xs {
    @apply w-12 h-12;
  }
  @screen md {
    @apply w-7 h-7;
  }
}
.button-scale {
  @apply w-28 h-7;
  @screen xxs {
    @apply w-48 h-10;
  }
  @screen xs {
    @apply w-64 h-12;
  }
  @screen sm {
    @apply w-80 h-12;
  }
  @screen md {
    @apply w-28 h-7;
  }
}
</style>
